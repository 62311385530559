<template>
  <a
    :href="
      $store.getters.getUserAccess
        ? `/admin/excavation/work?id=${marker.id}`
        : '#'
    "
    class="r-map-balloon pointer pa-4 flex-direction-column flex"
  >
    <p class="roquefort mb-1">
      {{ marker.description || "—" }}
    </p>
    <p class="сaprino opacity-72">
      {{ marker.address || "—" }}
    </p>
    <div class="flex align-start mt-3 mozzarella">
      <r-icon size="16" fill="rocky" icon="calendar" class="mr-2" />
      {{
        (getFormattedDate(marker.dateFrom) || marker.dateFrom || "Не указано") +
        " — " +
        (getFormattedDate(marker.dateToSumm) ||
          marker.dateToSumm ||
          "Не указано") +
        (marker.completed_info.isComplete ? ", работы завершены" : "")
      }}
    </div>
    <div class="flex align-start mt-3 mozzarella">
      <r-icon size="16" fill="rocky" icon="mayor" class="mr-2" />
      {{ getFirmName(marker) }}
    </div>
    <div class="mt-3">
      <a
        :href="'/appeals/add?categoryId=22'"
        target="_blank"
        class="flex align-items-center sulguni color-rocky opacity-72"
      >
        <r-icon size="16" fill="rocky" icon="send" class="mr-2" />
        Сообщить о нарушении
      </a>
    </div>
  </a>
</template>

<script>
import { formattedDate } from "../helpers/utils";

export default {
  name: "BalloonCard",
  props: {
    marker: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadImage: false,
    };
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(date);
    },
    getFirmName(marker) {
      if (marker.firmName) return marker.firmName;

      const parts = [];
      if (marker.applicantName != null) parts.push(marker.applicantName);
      if (marker.applicantSurname != null) parts.push(marker.applicantSurname);
      if (marker.applicantPatronymic != null) parts.push(marker.applicantPatronymic);

      return parts.length > 0 ? parts.join(" ") : "Не указано";
    },
  },
};
</script>

<style lang="scss" scoped>
a.opacity-72 {
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
}
.r-map-balloon {
  width: 244px;
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e4edfb;
    overflow: hidden;
  }
  &__info {
    padding: 12px 16px 0;
  }
}
</style>
